/* eslint-disable prettier/prettier */
import {
  Component,
  OnInit,
  OnChanges,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { DataType } from 'src/app/common/enums';
import { SelectComponent } from 'src/app/components/select/select.component';
import {
  DATA_TYPE_ICONS,
  DEFAULT_TRAITS,
  USER_FILTER_TRAITS,
} from 'src/app/constants';
import { ProjectService } from 'src/app/services/project/project.service';
import { SessionService } from 'src/app/services/session/session.service';

type Trait = {
  id: string;
  type: DataType;
  isDefaultTrait: boolean;
  icon: string;
  label: string;
  value: string;
};

@Component({
  selector: 'app-trait-select',
  templateUrl: './trait-select.component.html',
  styleUrls: ['./trait-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TraitSelectComponent implements OnInit, OnChanges {
  isLoading = false;
  loadMoreLoader = false;
  searchString$ = new BehaviorSubject('');
  traitsList$: BehaviorSubject<Trait[]> = new BehaviorSubject([]);
  allTraitsLoaded = false;
  currentOffset = 0;
  userTraitsSameAsDefaultCount = 0;
  apiCallInProgress = false;
  @ViewChild(SelectComponent, { static: true }) traitSelect?: SelectComponent;
  @Input() isUserPageFilter: boolean = false;
  @Input() selected: {
    value?: string;
    isDefaultTrait?: boolean;
  } | null = null;
  @Input() allowAddingCustom = false;
  @Input() useDefaultTraits = true;
  @Input() isOpen = false;

  @Output() setTraitType: EventEmitter<Trait> = new EventEmitter();

  constructor(
    private projectService: ProjectService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.setTraits();
  }

  ngOnChanges({ useDefaultTraits, isOpen }: SimpleChanges): void {
    if (useDefaultTraits && !useDefaultTraits.firstChange) {
      this.setTraits();
    }

    if (isOpen && isOpen.currentValue === true && this.selected?.value) {
      this.setSearchString(this.selected?.value);
    }
  }

  get selectedLabel() {
    if (this.selected?.value) {
      const { value, isDefaultTrait } = this.selected;

      return this.traitsList$.value.find(
        t => t.value === this.getTraitValue(value, isDefaultTrait)
      )?.label;
    }
    return '';
  }

  private async loadTraits(firstLoaded = true) {
    const projectId = this.sessionService.getActiveProject();
    const defaultTraitsCount = this.useDefaultTraits ? 7 : 6;
    if (projectId) {
      this.isLoading = firstLoaded;
      this.apiCallInProgress = true;
      try {
        let traits = await this.projectService.getTraits({
          projectId,
          offset: this.currentOffset,
          search: this.searchString$.value,
        });
        const totCurrentLength =
          this.traitsList$.value.length +
          traits.length +
          this.userTraitsSameAsDefaultCount -
          defaultTraitsCount;
        this.allTraitsLoaded = !(totCurrentLength >= this.currentOffset + 10);

        if (this.currentOffset === 0) {
          this.initSearch();
        }

        /* remove default traits from the list */

        /*   ADDING COUNT INTO VARIABLE DUE TO COUNT CORRECTION */
        // console.log(traits.map(trait =>  DEFAULT_TRAITS.every(t => t.value !== trait.trait) ));
        let defaultTraits = traits
          .map(trait => DEFAULT_TRAITS.every(t => t.value !== trait.trait))
          .filter(trait => trait === false);

        this.userTraitsSameAsDefaultCount += defaultTraits.length;

        traits = traits.filter(trait =>
          DEFAULT_TRAITS.every(t => t.value !== trait.trait)
        );

        this.traitsList$.next([
          ...this.traitsList$.value,
          ...traits.map(trait => {
            return {
              id: trait.trait,
              type: trait.data_type,
              label: trait.trait,
              isDefaultTrait: false,
              value: this.getCustomTraitValue(trait.trait),
              icon: DATA_TYPE_ICONS[trait.data_type],
            };
          }),
        ]);
      } catch {
        this.traitsList$.next([]);
      } finally {
        this.isLoading = false;
        this.loadMoreLoader = false;
        this.apiCallInProgress = false;
      }
    }
  }

  private setDefaultTraits() {
    DEFAULT_TRAITS.map(trait => {
      if (
        !this.traitsList$.value.find(({ label }) => label === trait.label) ||
        (this.searchString$.value &&
          trait.label.trim().toLowerCase().includes(this.searchString$.value))
      ) {
        this.traitsList$.next([
          ...this.traitsList$.value,
          {
            id: trait.value,
            ...trait,
            value: this.getDefaultTraitValue(trait.value),
            isDefaultTrait: true,
          },
        ]);
      }
    });

    if (this.isUserPageFilter) {
      USER_FILTER_TRAITS.map(trait => {
        if (
          !this.traitsList$.value.find(({ label }) => label === trait.label) ||
          (this.searchString$.value &&
            trait.label.trim().toLowerCase().includes(this.searchString$.value))
        ) {
          this.traitsList$.next([
            ...this.traitsList$.value,
            {
              id: trait.value,
              ...trait,
              value: this.getDefaultTraitValue(trait.value),
              isDefaultTrait: true,
            },
          ]);
        }
      });
    }
  }

  private initSearch() {
    this.traitsList$.next([]);
    this.currentOffset = 0;
    this.allTraitsLoaded = false;
    this.setDefaultTraits();
  }
  private setTraits() {
    let traits = this.useDefaultTraits
      ? DEFAULT_TRAITS.map(trait => ({
          id: trait.value,
          ...trait,
          value: this.getDefaultTraitValue(trait.value),
          isDefaultTrait: true,
        }))
      : [];

    if (this.isUserPageFilter && traits.length > 0) {
      traits = traits.concat(
        USER_FILTER_TRAITS.map(trait => ({
          id: trait.value,
          ...trait,
          value: this.getDefaultTraitValue(trait.value),
          isDefaultTrait: true,
        }))
      );
    }
    this.traitsList$.next(traits);

    this.loadTraits();
  }

  getTraitValue(id: string, isDefaultTrait = false) {
    return isDefaultTrait
      ? this.getDefaultTraitValue(id)
      : this.getCustomTraitValue(id);
  }

  getDefaultTraitValue(value: string) {
    return `${value}-default`;
  }

  getCustomTraitValue(value: string) {
    return `${value}-custom`;
  }

  select(model: { label: string; value: any }) {
    const trait = this.traitsList$.value.find(
      trait => trait.value === model.value
    );

    if (trait) {
      this.setTraitType.emit({
        ...trait,
        value: trait.id,
      });

      this.searchString$.next('');
    }
  }

  setSearchString(searchString: string) {
    this.searchString$.next(searchString);
    this.initSearch();
    this.loadTraits(true);
  }

  addCustom() {
    const search = this.searchString$.value;

    if (search && !this.traitsList$.value.some(t => t.value === search)) {
      const customTrait = {
        id: search,
        value: search,
        label: search,
        type: DataType.STRING,
        icon: DATA_TYPE_ICONS[DataType.STRING],
        isDefaultTrait: false,
      };

      this.setSearchString('');

      this.traitsList$.next([
        ...this.traitsList$.value,
        { ...customTrait, value: this.getCustomTraitValue(customTrait.value) },
      ]);

      this.setTraitType.emit(customTrait);
    }
  }

  handleKeyPress(event: KeyboardEvent) {
    event.stopPropagation();

    const search = this.searchString$.value;

    if (event.key === 'Enter' && search && this.selected?.value !== search) {
      this.addCustom();

      this.traitSelect?.hide();
    }
  }

  loadMoreTraits() {
    if (this.apiCallInProgress === false) {
      this.currentOffset += 10;
      this.loadMoreLoader = true;
      this.loadTraits(false);
    }
  }
}
