import { DataType } from 'src/app/common/enums';
import { DATA_TYPE_ICONS } from 'src/app/constants';

export const DEFAULT_TRAITS = [
  // {
  //   type: DataType.ARRAY,
  //   icon: DATA_TYPE_ICONS[DataType.ARRAY],
  //   label: 'Test Array',
  //   value: 'test-array',
  // },
  {
    type: DataType.DATE,
    icon: DATA_TYPE_ICONS[DataType.DATE],
    label: 'First Seen',
    value: 'first-seen',
  },
  {
    type: DataType.DATE,
    icon: DATA_TYPE_ICONS[DataType.DATE],
    label: 'Last Seen',
    value: 'last-seen',
  },
  {
    type: DataType.STRING,
    icon: DATA_TYPE_ICONS[DataType.STRING],
    label: 'Country',
    value: 'country',
  },
  {
    type: DataType.STRING,
    icon: DATA_TYPE_ICONS[DataType.STRING],
    label: 'City',
    value: 'city',
  },
  {
    type: DataType.BOOLEAN,
    icon: DATA_TYPE_ICONS[DataType.BOOLEAN],
    label: 'Wi-Fi',
    value: 'wi-fi',
  },
  {
    type: DataType.STRING,
    icon: DATA_TYPE_ICONS[DataType.STRING],
    label: 'Email',
    value: 'email',
  },
];

export const USER_FILTER_TRAITS = [
  {
    type: DataType.STRING,
    icon: DATA_TYPE_ICONS[DataType.STRING],
    label: 'User ID',
    value: 'user_id',
  },
];
